<template>
  <div id="app">
    <div class="header" ref="header" v-bind:class="{'header-show': $root.showheader, 'header-scrolled': $root.hasScroll }">
      <div class="header-navigation">
        <div class="header-navigation-left" v-if="$root.menufooter[Object.keys($root.menufooter)[0]]">
          <router-link class="router" :to="{ name:  $root.menufooter[Object.keys($root.menufooter)[0]].name, params: { lang: $root.language[$root.languageid], view: $root.menufooter[Object.keys($root.menufooter)[0]].name}}">Studio Gretzinger</router-link>
        </div>
        <div class="header-navigation-center">
          <a v-bind:class="{'tag-index-active': $root.showindex}" v-on:click="$root.showindex = !$root.showindex" class="router desktop-inline tag tag-index">Index</a>
          <a v-bind:class="{'tag-filter-active': $root.showfilter}" v-on:click="toggleFilter();" class="router desktop-inline tag tag-filter">Filter</a>
          <form v-on:submit.prevent="submit(this)"><input class="searchinput desktop-inline" type="text" placeholder="Search" v-model="$root.searchquery"></form>
          <!--<router-link class="router desktop-inline" :to="{ name: 'search', params: { lang: $root.language[$root.languageid], view: 'Search'}}">Search</router-link>-->
        </div>        
        <div class="header-navigation-right">
              <router-link class="desktop-inline" v-if="(($route.name=='home' && !$root.showindex) || $root.showfooter===true) && $root.languageid==1" to="/De">DE</router-link>
              <router-link class="desktop-inline" v-if="(($route.name=='home' && !$root.showindex) || $root.showfooter===true) && $root.languageid==0" to="/En">EN</router-link>
              <router-link v-if="($route.name!='home' && !$root.showindex) && $root.showfooter===false" class="close" to="/"></router-link>
              <a v-if="$root.showindex && $root.showfooter===false" class="desktop-inline close" v-on:click="$root.showindex = false"></a>
              <a v-if="$route.name=='home'" v-bind:class="{'tag-index-active': $root.showindex}" v-on:click="$root.showindex = !$root.showindex" class="mobile-inline tag tag tag-index">Index</a>

        </div>
      </div>
      <transition name="header"  mode="out-in">
        <router-view name="header"></router-view>
      </transition>
    </div>
    <div class="footer" v-bind:class="{'footer-show': $root.showfooter}">
      <div class="footer-navigation footer-navigation-first">
        <div class="header-navigation-left">
          <router-link class="router" to="/">Studio Gretzinger</router-link>
        </div>
        <div class="header-navigation-right">
              <router-link class="close" to="/"></router-link>
        </div>
      </div>
      <div class="footer-navigation footer-navigation-second">
        <div class="footer-navigation-left">
          <span v-bind:key="route.id" v-if="route.float == 'left'" v-for="route in $root.menufooter">
            <router-link class="router" :to="{ name: route.name, params: { lang: $root.language[$root.languageid], view: route.name}}">{{route.translation[$root.languageid]}}</router-link>
          </span>
        </div>
        <div class="footer-navigation-right">
          <router-link class="mobile-inline" v-if="($route.name=='home' || $root.showfooter===true) && $root.languageid==1" to="/De">DE</router-link>
          <router-link class="mobile-inline" v-if="($route.name=='home' || $root.showfooter===true) && $root.languageid==0" to="/En">EN</router-link>
        </div>
      </div>
       <transition name="footer"  mode="out-in">
        <router-view name="footer"></router-view>
       </transition>
    </div>
    <Imagefilter/>
    <Search/>
    <Index/>
    <Gallery/>
    
    <transition name="project">
      <router-view name="detail"></router-view>
    </transition>     

    <router-view name="alert"></router-view> 
  </div>
</template>

<style lang="scss">
  @import "main";
  .header,
  .footer {

  position: fixed;
  transition: $standard-transition;

  &-enter-active, &-leave-active {
    transition: opacity .1s;
  }
  &-enter, &-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  
  &-scroll-content {
    padding: $standard-space;
    padding-top: 0em;
    top: $header-height;
    bottom: 0px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; 
    position: absolute;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  &-navigation {
    height: $footer-height;
    position: absolute;
    width: 100%;
    padding: $footer-height/2 $standard-space;
    line-height: 0;
    &-left, &-right, &-center {
      top: $footer-height/4;
      line-height: 0;
      position: absolute;
    }
    &-left {
      left: $standard-space;
    }
    &-right {
      right: $standard-space;
      a {
        margin-left: $large-space;
        &.nospace {
          margin-left: 0px;
        }
      }
    }
    &-center {
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      a {
        margin-left: $large-space / 2;
        margin-right: $large-space / 2;
      }
    }
    a {
      @include mediumfont;
      &.close {
        
      }
    }
  }
  a.tag {
    border: 1px solid;
    border-radius: $large-space;
    height: $large-space;
    padding-bottom: $large-space / 12;
    padding-left: $large-space / 2;
    padding-right: $large-space / 2;
    user-select: none;
    &-filter {
      cursor: pointer;
      &-active {
        background-image: url('~@/assets/images/button-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    &-index {
      cursor: pointer;
      &-active {
        background: #000;
        color: #FFF;
        border-color: #000;
      }
    }    
  }
}
  .header {
    z-index: 4;
    top: 0em;
    width: 100%;
    left: 0em;
    height: $header-height;
    background: $header-color;
    color: $header-font-color; 
    overflow: hidden;
    border-bottom: 1px solid;
    &-show {
      height: 100%;
      overflow-y: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    &-scrolled {
      transform: translateY(-100%);
    }
  &-scroll-content {
    bottom: $standard-space;
    @include st {
      bottom: 0px;
    }
  }
  form {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .searchinput {
    @include mediumfont;
    border: none;
    margin: 0;
    padding-left: $large-space / 2;
    padding-right: $large-space / 2;
    -webkit-appearance: none;
    width: 150px;
    &:focus{
      outline: none;
    }
  }
}

  .footer {
    z-index: 6;
    width: 100%;
    bottom: 0px;
    left: 0em;
    background: $footer-background;
    top: 0em;
    overflow: hidden;
    transform: translateY(-100%);
    &-show {
      transform: translateY(0%);
    }
    &-navigation {
      background: linear-gradient($footer-background, $footer-background, rgba($footer-background, 0));
      &-left {
        a {
          margin-right: $standard-space;
          @include sm {
            margin-right: $standard-space / 2;
          }
        }
      }
      &-first {
        border-bottom: 1px solid;
        background: transparent;
      }
      &-second {
        top: $footer-height;
        background: transparent;
      }
    }
    &-scroll-content {
      top: $header-height * 2;
    }
  }
  

</style>

<script>

/* eslint-disable */

import Gallery from '@/components/Gallery.vue'
import Imagefilter from '@/components/Filter.vue'
import Index from '@/components/Index.vue'
import Search from '@/components/Search.vue'

export default {
  name: 'home',
  components: {
    Gallery,
    Imagefilter,
    Index,
    Search
  },
  methods: {
    toggleFilter: function() {
      if (this.$root.showfilter === true) {
        this.$store.commit('clearFilter'); 
        this.$root.showfilter = false;
      }
      else {
        this.$root.showfilter = true
      }
    },
    submit: function() {
      if (this.$root.searchquery !== "") {
        this.$root.showsearch = true;
      }
    }
  }
}
</script>
