import Vue from 'vue'
import Router from 'vue-router'
import home from './views/Home.vue';
import project from './views/Project.vue';
import PageNotFound from './views/PageNotFound.vue';


Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      components: {
        main: home
      }
    },
    {
      path: `/:lang(De|En)/:view(Project)/:name/:id(\\d+)`,
      name: 'project',
      components: {
        detail: project
      }
    },    
    {
      path: '/:lang(De|En)',
      name: 'language'
    },
    {
      path: "/404", 
      components: {
        alert: PageNotFound
      } 
    }
  ]
})


router.beforeEach((to, from, next) => {
  document.title = `Studio Katja Gretzinger${to.params.view ? ' – ' + to.params.view : ''}${to.params.name ? ' – ' + to.params.name : ''}`;
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));


  // Set the canonical URL dynamically based on the route
  const canonicalUrl = `https://studiogretzinger.de${to.path}`;
  const link = document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', canonicalUrl);
  link.setAttribute('data-vue-router-controlled', ''); // Add custom attribute to identify it later

  // Append the canonical tag to the document head
  document.head.appendChild(link);

  return next();
})

export default router