<template>
  <transition name="index">
    <div class="index"  v-if="$root.showindex" :style="`margin-top: ${$root.filterTopOffset}px`">
        <table class="fixed">
            <thead>
                <tr>
                    <td>
                        <a v-on:click="orderedUsers = {order: 'Data.Year.Content'}">&#8693;</a>
                    </td>
                    <td>
                        <a :class="ordercol=='_categoryarray[0][0]' ? ordermode : ''" v-on:click="orderedUsers = {order: '_categoryarray[0][0]'}">Category</a>
                    </td>
                    <td>
                        <a :class="ordercol=='Data.Title.Parsed[0]' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data.Title.Parsed[0]'}">Title</a>
                    </td>
                    <td>
                        <a :class="ordercol=='_reference[0].lastname' ? ordermode : ''" v-on:click="orderedUsers = {order: '_reference[0].lastname'}">Reference</a>
                    </td>
                    <td>
                        <a :class="ordercol=='Data.Tag.Content' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data.Tag.Content'}">Tag</a>
                    </td>
                </tr>
            </thead>
        </table>            
        <table>
            <tbody>
                <router-link 
                    tag="tr" 
                    :to="{ name: 'project', params: { lang: $root.language[$root.languageid], view: 'Project', id: doc.Contribution.Id, name: $root.u(doc.Contribution.Name)}}" 
                    v-bind:key="doc.Contribution.Id" 
                    v-for="doc in orderedUsers"
                    v-if="doc.visible === true"
                >
                    <td>{{doc.Data.Year.Content | yearformat}}</td>
                    <td><span v-bind:key="ckey" v-html="ref[$root.languageid] ? ref[$root.languageid] : ref[0]" v-for="(ref, ckey) in doc._categoryarray"></span></td>
                    <td v-html="doc.Data.Title.Parsed[$root.languageid] ? doc.Data.Title.Parsed[$root.languageid] : doc.Data.Title.Parsed[0]"></td>
                    <td><span v-bind:key="rkey" v-html="ref.fullname" v-for="(ref, rkey) in doc._reference"></span></td>
                    <td><span v-bind:key="tkey" v-for="(tag, tkey) in doc.Data.Tag.Content">{{tag}}</span></td>
                </router-link>

            </tbody>    
        </table>
    </div>
  </transition>
</template>

<script>
import _ from 'lodash'
export default {
    data() {
        return ({
            ordercol: 'Contribution.GrId',
            ordermode: 'asc'
        });
    },
  computed: {
    orderedUsers: {
        get: function () {
            return _.orderBy(this.$store.state.gallery.Documents, this.ordercol, this.ordermode)    
        },
        set: function (value) {
            if (this.ordercol == value.order) {
                this.ordermode = this.ordermode == 'desc' ? 'asc' : 'desc'
            }
            else {
                this.ordercol = value.order
                this.ordermode = 'asc'
            }
        }
    }
  }
}
</script>

<style lang="scss">
 @import "../main";
 .index {
     padding-top: 0em;
     overflow: hidden;
     position: absolute;
     background: #FFF;
     left: 0px;
     top: $header-height;
     bottom: 0px;
     right: 0px;
     z-index: 1;
     table {
        width: 100%;
        height: 100%;
        border: none;
        margin: 0;
        border-collapse: collapse;
        table-layout:fixed;
        position: absolute;
        display: block;
        overflow: hidden;
        overflow-y: auto;
         -webkit-overflow-scrolling: touch;
         &.fixed {
             z-index: 2;
             height: $header-height / 1.5;
             border-bottom: 1px solid;
            overflow: hidden;
             tr {
                 border-bottom: none !important;
             }
         }
        thead {
            position: absolute;
            top: 0em;
            left: 0em;
            table-layout:fixed;
            display: table;
            width: 100%;
            background: #FFF;
        }
        tbody {
            overflow-x: hidden;
            overflow-y: auto;
            margin-top: $header-height / 1.5;
            display: table;
            width: 100%;
            table-layout:fixed;
        }
         tr {
             border-bottom: 1px solid;
             width: 100%;
         }
         td {
            cursor: pointer;
            padding-left: $standard-space;
            padding-right: $standard-space;
             vertical-align: top;
             padding-top: $standard-space / 2;
             padding-bottom: $standard-space / 2;
             overflow: hidden;
             text-overflow: ellipsis;
             width: 27.666666666666667%;
             &:nth-child(1) {
                 width: 7%;
             }
             &:nth-child(2) {
                 width: 10%;
             }     
             @include st {
                width: 27%;
                &:nth-child(1) {
                    width: 8%;
                }
                &:nth-child(2) {
                    width: 11%;
                }
             }        
             
             span {
                 &:after {
                     content: ", ";
                 }
                 &:last-child {
                    &:after {
                        content: "";
                    }   
                 }
             }
             @include sm {
                display: none;
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: table-cell;
                }             
                &:nth-child(1) {
                    width: 25% !important;
                }
                &:nth-child(2) {
                    width: 25% !important;
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                }
                &:nth-child(3) {
                    width: 50% !important;
                }
            }
            a {
                &.asc {
                    &:after {
                        content: " A-Z"
                    }
                }
                &.desc {
                    &:after {
                        content: " Z-A"
                    }
                }
            }
         }
     }
    transform: translateY(0%);
    transition: all .25s;
    &-enter, &-leave-to /* .fade-leave-active below version 2.1.8 */ {
        transform: translateY(-100%);
    }
 }
</style>