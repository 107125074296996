<template>
  <transition name="search">
    <div class="search" v-if="$root.showsearch">
      <div class="header">
        <div class="header-navigation">
          <div class="header-navigation-left" v-if="$root.menufooter[Object.keys($root.menufooter)[0]]">
            <router-link class="router" :to="{ name:  $root.menufooter[Object.keys($root.menufooter)[0]].name, params: { lang: $root.language[$root.languageid], view: $root.menufooter[Object.keys($root.menufooter)[0]].name}}"><span v-on:click="$root.showsearch = false; $root.searchquery = '';">Studio Gretzinger</span></router-link>
          </div>
          <div class="header-navigation-right">
            <a class="close" v-on:click="$root.showsearch = false; $root.searchquery = '';"></a>
          </div>
        </div>
      </div>
      <div class="header-scroll-content" v-if="data!==null">
        <form v-bind:class="{'form-header': data!==null}" v-on:submit.prevent="submit()">
          <div class="col1">
            <label>Search:</label>
            <input placeholder="Search…" v-model="$root.searchquery">
          </div>
          <a class="tag" v-on:click="submit()">&#8617;</a>
        </form>
        <table v-if="data && data.Documents.length > 0">
            <thead>
              <tr>
                <td>Document</td>
                <!--<td>Image</td>-->
                <td>Excerpt</td>
              </tr>
            </thead>
            <tbody>
                <router-link tag="tr" :to="doc.url" v-bind:key="doc.Contribution.Id" v-for="doc in data.Documents">
                    <td  v-on:click="$root.showsearch = false" v-html="doc.Contribution.Name"></td>
                    <!--<td  v-on:click="$root.showsearch = false"><img :src="doc.thumb"></td>-->
                    <td  v-on:click="$root.showsearch = false"><span v-html="doc.excerpt"></span></td>
                </router-link>

            </tbody>    
        </table>
        <div class="search-foot">
          <p>&nbsp;</p>
          <p v-if="data">Displaying {{data.Documents.length}} of {{raw.NumFound}} search results for "{{result}}" in {{Math.round(raw.QueryTime * 100)/100}}s.</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      data: null,
      raw: null,
      result: "",
      error: null
    }
  },
  watch: {
    '$root.showsearch': function(e) {
      if (e === true) {
        this.submit();
      }
    }
  },
  methods: {
    submit () {
      if (this.$root.searchquery == "" || this.$root.searchquery.length < 3) return;
      //console.log(this.query)
      let _params =  {
            query: this.$root.searchquery,
            populate: true,
            limit: 20,
            offset: 0,
            references: false,
            status: this.$store.state.status
      }
      try {
        this.$store.dispatch('loadData', {params: _params, mode: 'contributions', issue: '1-2', chapter: '1-2-3-4-5-6-7-8-9-10'}).then((data) => {
          this.result = this.$root.searchquery;
          this.raw = data;
          this.data = {Documents: []};
          data.Documents.forEach((element) => {
            if (element.Contribution.Forissue == 2) {
              element.url = `/${this.$root.language[this.$root.languageid]}/${element.Contribution.ForchapterName}`;
            }
            if (element.Contribution.Forchapter == 1) {
              element.url = `/${this.$root.language[this.$root.languageid]}/Project/${this.$root.u(element.Contribution.Name)}/${element.Contribution.Id}`;
            }
            if (element.Contribution.Forchapter == 4) {
              element.url = `/${this.$root.language[this.$root.languageid]}/References`;
            }
            element.thumb = false;
            if (element.Data.ImagesStart && element.Data.ImagesStart.Content[0].Files.Resized[1]) {
              element.thumb = element.Data.ImagesStart.Content[0].Files.Resized[1];
            }
            element.excerpt = "";
            if (element.Data.Title && element.Data.Title.Parsed && typeof element.Data.Title.Parsed === "object") {
              element.excerpt += " " + element.Data.Title.Content[this.$root.languageid];
            }
            if (element.Data['Teasertext Frontpage'] && element.Data['Teasertext Frontpage'].Parsed && typeof element.Data['Teasertext Frontpage'].Parsed === "object") {
              element.excerpt += " " + element.Data['Teasertext Frontpage'].Content[this.$root.languageid];
            }
            if (element.Data['Longtext Projectpage'] && element.Data['Longtext Projectpage'].Parsed && typeof element.Data['Longtext Projectpage'].Parsed === "object") {
              element.excerpt += " " + element.Data['Longtext Projectpage'].Content[this.$root.languageid];
            }            
            if (element.Data.Columns && element.Data.Columns.Content && typeof element.Data.Columns.Content === "object") element.Data.Columns.Content.forEach((e) => {
              element.excerpt += " " + e;
            })

            if (element.Data.Images && element.Data.Images.Content && typeof element.Data.Images.Content === "object") element.Data.Images.Content.forEach((e) => {
              if (e.Captions[1 + this.$root.languageid] && e.Captions[1 + this.$root.languageid] !== "false") element.excerpt += " " + e.Captions[1 + this.$root.languageid];
              if (e.Captions[3 + this.$root.languageid] && e.Captions[3 + this.$root.languageid] !== "false") element.excerpt += " " + e.Captions[3 + this.$root.languageid];
            })

            if (element.Data.Title && element.Data.Title.Parsed && typeof element.Data.Title.Parsed === "string") 
              element.excerpt += " " + element.Data.Title.Parsed;

            let _s = element.excerpt.toLowerCase().indexOf(this.$root.searchquery.toLowerCase()) - 75
            _s = _s < 0 ? 0 : _s;
            let _e = 150;
            let _frag = element.excerpt.substr(_s, _e);
            _frag= _frag.substr(_frag.indexOf(' '), _frag.lastIndexOf(' ') - _frag.indexOf(' ')).trim();

            element.excerpt = (_s>0?"…":"") + _frag + (_s+_e<element.excerpt.length?"…":"");
            element.excerpt = element.excerpt.replace(/(\r\n|\r|\n)/g, ' ');
            element.excerpt = element.excerpt.replace(/<\/?[^>]+(>|$)/g, "")

            if (element.excerpt == "") {
              element.excerpt = "…"
            }

            this.data.Documents.push(element);
          }, this);
        });  
      } catch (error) {
        this.error = error;
      }
      return false;
    }
  }
}
</script>

<style lang="scss">
  @import "../main";
  .search {
    z-index: 30;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    transform: translateY(0%);
    transition: all .25s;
    background: #FFF;
    &-enter, &-leave-to /* .fade-leave-active below version 2.1.8 */ {
        transform: translateY(-100%);
    }
    &-foot {
      padding: $standard-space;
    }
    &  .header-scroll-content {
      bottom: 0px !important;
      padding: 0em;
    }
    table {
        width: 100%;
        border: none;
        margin: 0;
        border-collapse: collapse;
        table-layout:fixed;
        tr {
            border-bottom: 1px solid;
        }
        td {
          cursor: pointer;
            vertical-align: top;
            padding-top: $standard-space / 2;
            padding-bottom: $standard-space / 2;
            padding-left: $standard-space;
            padding-right: $standard-space;
            overflow: hidden;
            text-overflow: ellipsis;
            &:nth-child(1) {
                width: 41.667%;
            }/*
            &:nth-child(2) {
                width: 10%;
                padding-right: $standard-space;
                img {
                  width: 100%;
                }
            }*/             
            &:nth-child(2) {
                width: 58.333%;
                span {
                  max-width: 625px;
                  display: inline-block;
                }
            }             
            @include sm {
              /*display: none;
              &:nth-child(1),
              &:nth-child(3) {
                  display: table-cell;
                  white-space: normal;
              }*/             
              &:nth-child(1) {
                padding-right: $standard-space;
              }
          }
          a {
              &.asc {
                  &:after {
                      content: " A–Z"
                  }
              }
              &.desc {
                  &:after {
                      content: " Z–A"
                  }
              }
          }
        }
        tbody {
          td {
            
            @include min_st() {
              @include mediumfont;
               padding-left: $standard-space / 1.5;
            }
           
          }
        }
    }    
    .form {

      &-header {

          @include sm() {
            text-align: right;
          }
          position: relative;

        .col1 {
          width: 41.667%;
          display: inline-block;
          margin-right: $standard-space / 2;
          @include sm() {
            position: absolute;
            left: $standard-space;
            width: 60%;
            text-align: left;
          }
        }

        border-bottom: 1px solid;
        padding: 0 $standard-space;
        height: $header-height;
        line-height: $header-height;

        

        label {
          @include mediumfont;
          padding-right: $standard-space / 2;
        }
        
        a {
          background: black;
          color: white;
          border: 2px solid black;
          border-radius: $large-space;
          height: $large-space;
          padding-bottom: $large-space / 12;
          padding-left: $large-space / 2;
          padding-right: $large-space / 2;
          @include mediumfont;
        }
        
        input {
          background: transparent;
          font: inherit;
          color: black;
          border-radius: 0;
          -webkit-appearance: none;
          
          @include mediumfont;
          max-width: 100px;
          width: 75%;
          border: none;

          &:focus {
              outline: none;
              -webkit-box-shadow: none;
              box-shadow: none;            
          }
        }
      }
    }
  }
::placeholder {
  font: inherit;
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  font: inherit;
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  font: inherit;
  color: inherit;
  opacity: 1;
}
</style>
