<template>
  <div class="footer-scroll-content" v-if="post">
    <div v-bind:key="doc.Contribution.Id" v-bind:class="doc.Contribution.FortemplateName" v-for="doc in post.Documents" v-if="doc.Data.Language && (doc.Data.Language.Content == $root.languageid || doc.Data.Language.Content == -1  || doc.Data.Language.Content == null)">
        <h1 v-if="doc.Data.Title.Parsed" v-html="doc.Data.Title.Parsed"></h1>
        <div class="col col-1" v-if="doc.Data.Columns.Parsed[0]" v-html="doc.Data.Columns.Parsed[0]"></div>
        <div class="col col-2" v-if="doc.Data.Columns.Parsed[1]" v-html="doc.Data.Columns.Parsed[1]"></div>
        <div class="col col-3" v-if="doc.Data.Columns.Parsed[2]" v-html="doc.Data.Columns.Parsed[2]"></div>
        <div class="col col-4" v-if="doc.Data.Columns.Parsed[3]" v-html="doc.Data.Columns.Parsed[3]"></div>
    </div>
    <div v-if="showfooterlink === true">
      <span class="smallink" v-bind:key="route.id" v-if="route.float == 'right'" v-for="route in $root.menufooter">
        <router-link class="router" :to="{ name: route.name, params: { lang: $root.language[$root.languageid], view: route.name}}">{{route.translation[$root.languageid]}}</router-link>
      </span>
    </div>
  </div>
</template>

<style lang="scss">

@import "../main";
.footer-scroll-content {
  padding-top: $standard-space !important;
  @include sm {
    margin-top: 0 !important;
  }
  .OneColumn,
  .TwoColumns,
  .ThreeColumns {
    .col {
      @include largefont;
      padding-bottom: $large-space;
    }
    h1,
    h2,
    h3 {
      @include largefont;
      margin: 0 0 $standard-space 0;
    }
  }
  .FourColumns {
    .col {
      float: left;
      width: 25%;
      padding-right: $standard-space;
      @include st {
        width: 33%;
      }
      @include sm {
        width: 100%;
        margin-bottom: $large-space;
      }
      h1,
      h2,
      h3 {
        font: inherit;
        margin-left: $large-space;
        margin-bottom: 0;
      }
      p {
        margin-bottom: $standard-space;
      }

    }
  }
  .TwoColumns {
    .col {
      max-width: 66%;
      @include st {
        max-width: 75%;
      }
      @include sm {
        max-width: 550px;
      }
      &-1 {
      }
      &-2 {
        columns: 2;
      }

    }
  }
  .smallink {
    @include mediumfont;
  }
  .reset {
    font-size: 0.333em;
    line-height: 1.02em;
    padding-bottom: 1em;
    display: inline-block;
    @include st() {
      font-size: 0.444em;
      line-height: 1em / $line-height-large;
    } 
    @include sm() {
      font-size: 0.6667em;
    }
  }
}

</style>

<script>
export default {
  data () {
    return {
      loading: false,
      post: null,
      error: null,
      showfooterlink: true
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route' () {
        this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.error = this.post = null
      this.loading = true
      try {
        this.$store.dispatch('loadData', {issue: this.$root.menufooter[this.$route.params.view].issue, chapter: this.$root.menufooter[this.$route.params.view].id}).then((data) => {
          this.post = data
          this.loading = false;
          this.showfooterlink = true;  
          this.post.Documents.forEach(d => {
            if (d.Contribution.FortemplateName === 'FourColumns')
              this.showfooterlink = false;  
          })
        });  
      } catch (error) {
        this.error = error;
      }
    }
  }  
}
</script>