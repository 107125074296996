import Vue from "vue"

Vue.filter("yearformat", value => {
    if (!value) return ''
    var d = new Date(value * 1000);
    return d.getFullYear();
})

Vue.filter("splitlanguage", (value, language) => {
    return value.split("|")[language]
})
