<template>
    <div class="div404">
        <h1>Page not Found </h1>
        Please navigate back to the <a class="router router-link-exact-active" href="/">main page</a>
    </div>
</template>

<style lang="scss">

@import "../main";
.div404 {
    z-index: 10000;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #FFF;
    padding: $large-space;
}

</style>

<script>
export default {
}
</script>