import Vue from 'vue'
import App from './App.vue'
import footerview from './views/About.vue';
import references from './views/References.vue';
import router from './router'
import store from './store'
import VueResource from 'vue-resource'
import VueMatomo from 'vue-matomo'
import "./filters/filters.js"

require('es6-object-assign/auto');

Vue.use(VueMatomo, {
  host: 'https://piwik.tr51.org',
  siteId: 22,
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  trackerFileName: 'piwik'
});




Vue.use(VueResource)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  data () {
    return {
      loading: true,
      post: null,
      error: null,
      language: ['De', 'En'],
      languageid: 0,
      menufooter: {},
      showfooter: false,
      showheader: false,
      hasScroll: false,
      showfilter: false,
      showindex: false,
      showsearch: false,
      searchquery: "",
      filterTopOffset: 0
    }
  },
  render: h => h(App),

  /** - Loading Routes from Rokfor
    * - Creating vue Routes
    **/

  beforeCreate: async function() {
    let menuissue = 2;
    let post = await store.dispatch('loadData', {mode: 'books', issue: menuissue});
    post.Books[0].Chapters.forEach(function(c){
      c.Options.forEach(function(co) {
        if (co.key == "menu" && co.value == "true") {
          let _float = "left";
          c.Options.forEach(function(v) {
            if (v.key=="float") {
              _float = v.value;
            }
          })
          Vue.set(this.menufooter, c.Name, {
            name: c.Name,
            id: c.Id,
            issue: menuissue,
            translation: [c.Localization[0].translation, c.Localization[1].translation],
            float: _float

          });
          this.$router.addRoutes([{
            path: `/:lang(De|En)/:view(${c.Name})`,
            name: c.Name,
            components: {
              footer: c.Name == "References" ? references: footerview
            }
          }])
        }
      }, this)
    }, this)
  },
  created: function () {
    try {
      // Footer Views

      let _c = this.$route.matched[0].components;
      this.showheader = _c.header ? true : false;
      this.showfooter = _c.footer ? true : false;

    } catch(err) {
      console.warn(err);
    }

    if (this.$route.params.lang) {
      this.languageid = this.$route.params.lang == 'De' ? 0 : 1;
    }

    // Load Projects (Gallery)
    store.dispatch('loadData', {issue: 1, chapter: 1, mutator: 'updateGallery'}).then(() => {
      // Stuff on initial load...
      this.loading = false;
    });
  },
  watch: {
    '$route' (to, from) {
      let _c = to.matched[0].components;
      this.hasScroll = false;

      if (this.$route.params.lang) {
        this.languageid = this.$route.params.lang == 'De' ? 0 : 1;
      }

      if (this.$route.name == "language") {
        let _t = { name: from.name, params: from.params};
        _t.params.lang = to.params.lang;
        this.$router.push(_t);
      }

      this.showheader = _c.header ? true : false;
      this.showfooter = _c.footer ? true : false;
    },
  },
  methods: {
    u(string) {
      return string.replace(/[\W_]+/g, '_');
    }
  }
}).$mount('#app')
