<template>
  <div class="gallery" :style="`margin-top: ${$root.filterTopOffset}px`">
    <div class="gallery-container">
      <div :class="`gallery-container-columns gallery-container-columns-${cols}`" v-bind:key="cols" v-for="cols in [1,2,3]">
        <div :class="`gallery-container-columns-${cols}-column gallery-container-columns-${cols}-column-${col}`" v-bind:key="col" v-for="col in cols">
          <transition-group name="gallery-item"  mode="in-out">
            <div 
              v-if="doc.visible && index % cols == col - 1 && doc.Data.ImagesStart && doc.Data.ImagesStart.Content && doc.Data.ImagesStart.Content[0]"
              v-bind:key="doc.Contribution.Id"  
              v-for="(doc, index) in $store.state.gallery.Documents"
              :class="`gallery-item gallery-item-${doc.Data.ImagesStartSize.Content}`"
            >
              <router-link 
                class="gallery-link" 
                :to="{ name: 'project', params: { lang: $root.language[$root.languageid], view: 'Project', id: doc.Contribution.Id, name: $root.u(doc.Contribution.Name)}}" 
                v-if="doc._startimage !== false"
              >
                <video autoplay loop muted v-if="doc._isvideo" class="gallery-image" v-bind:class="{'gallery-image-noshadow': doc.Data.ImagesStart.Content[0].Captions[0]==='noshadow' }" :src="doc._startimage"/>
                <img v-else class="gallery-image" v-bind:class="{'gallery-image-noshadow': doc.Data.ImagesStart.Content[0].Captions[0]==='noshadow' }" :src="doc._startimage">
                <div class="gallery-description" v-if="doc.Data['Teasertext Frontpage']" v-html="doc.Data['Teasertext Frontpage'].Parsed[$root.languageid]"></div>
              </router-link>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss">
@import "../main";
.gallery {
  z-index: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: $header-height * 1;
  padding-bottom: $header-height * 3;
  width: 100%;
  overflow-y: auto;  
  height: 100%;
  position: absolute;
  -webkit-overflow-scrolling: touch;
  transition: all .25s;

  &-container {
    width: 100%;
    margin: auto;
    text-align: center;
    &-columns {
      display: none;
      width: 100%;
      &-1 {
        max-width: 460px;        
        padding-left: 30px;
        padding-right: 30px;
        @include sm() {
          display: inline-block;
        }  
        &-column {
          width: 100%;
        }
      }
      &-2 {
        max-width: 1100px;
        padding-left: 50px;
        padding-right: 50px;
        @include lg() {
          display: inline-block;
        }    
        @include sm() {
          display: none;
        }
        &-column {
          width: 50%;
          float: left;
          &-1 {
            text-align: right;
            padding-right: $standard-space / 2;
          }    
          &-2 {
            text-align: left;
            padding-left: $standard-space / 2;
          }    

        }
      }
      &-3 {
        max-width: 1800px;    
        padding-left: 90px;
        padding-right: 90px;            
        display: inline-block;
        @include lg() {
          display: none;
        }    
        @include st() {
          display: none;
        }    
        @include sm() {
          display: none;
        }            
        &-column {  
          width: 33%;
          float: left;
          padding-right: $standard-space / 2;
          padding-left: $standard-space / 2;
          &-1 {
            text-align: right;
          }    
          &-2 {
          } 
          &-3 {
            text-align: left;
          } 
        }
      }
    }
  }




  &-item {
    display: inline-block;
    padding-top: $standard-space;
    padding-bottom: $standard-space;
    width: 100%;
    opacity: 1;
    &-2 {
      width: 100%;
      @include sm() {
        width: 100%;
      }

    }
    &-1 {
      width: 90%;
      @include sm() {
        width: 100%;
      }
    }
    &-0 {
      width: 80%;
      @include sm() {
        width: 100%;
      }
    } 
    &-enter-active {
      transition: opacity .25s;
    }
    &-enter /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  }

  &-link {
    width: 100%;
    text-align: left;
  }
  &-image {
    width: 100%;
    &-noshadow {
      box-shadow: none;
    }    
  }
  &-description {
    padding: $standard-space / 2;
  }
}

</style>