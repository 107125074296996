<template>
  <div class="project" v-bind:style="{'--header-height': headerHeight, '--permheader-height': permheaderHeight}" v-bind:class="{'project-scrolled': hasScroll}" v-if="loaded === true" v-on:resize="resize()">


    <div class="fix-header fix-header-dynamic" ref="fixheader">
      <div class="fix-header-row">
        <router-link class="router" to="/">Studio Gretzinger</router-link>
        <router-link class="close" to="/"></router-link>
      </div>
      <div class="fix-header-row">
          <span>{{post._header._year | yearformat}}</span>
          <span v-if="Object.keys(post._header._category).length > 0">,
            <a class="pfilterlink" href="#" v-bind:class="{'pfilterlink-active': classActive==key}" v-on:click="toggleClass(key)" v-bind:key="key" v-for="(c, key) in post._header._category" v-html="c[$root.languageid]"></a>
          </span>
      </div>
      <div class="fix-header-row">
        <span v-if="Object.keys(post._header._reference).length > 0">
          <a class="pfilterlink" v-bind:href="r.url" target="_blank" v-bind:key="key" v-for="(r, key) in post._header._reference" v-html="r.fullname"></a>,
        </span>
        <span v-html="post._header._title[$root.languageid]"></span>
      </div>
    </div>
    <div class="fix-header fix-header-permanent" ref="permheader">
      <div class="fix-header-row">
        <router-link class="close" to="/"></router-link>
        <span v-if="Object.keys(post._header._reference).length > 0">
          <a class="pfilterlink" v-bind:href="r.url" target="_blank" v-bind:key="key" v-for="(r, key) in post._header._reference" v-html="r.fullname"></a>,
        </span>
        <span v-html="post._header._title[$root.languageid]"></span>
      </div>
    </div>

    <div class="project-scroller"  v-bind:style="{paddingTop: headerHeight}"   v-on:scroll="measure()" ref="body">
      <div 
        class="page page-adaptive section" 
        v-if="post.Data['Longtext Projectpage'].Parsed[$root.languageid]"
        v-bind:style="{background: `rgb(${post._pages[0] && post._pages[0]._image && post._pages[0]._image.Captions[5]?post._pages[0]._image.Captions[5]:'255,255,255'})`}"
      >
        <div class="page-text">
          <div class="page-text-container">
            <p v-html="post.Data['Longtext Projectpage'].Parsed[$root.languageid]"></p>
            <div v-if="post.Data['Downloads'] && post.Data['Downloads'].Content && post.Data['Downloads'].Content.length > 0">
                <br/>
                <p v-bind:key="dkey" v-for="(d, dkey) in post.Data['Downloads'].Content">
                    <a :href="d.Files.Original" target="_blank">{{d.Captions[$root.languageid]}}</a>
                </p>
            </div>  
          </div>
        </div>
      </div>
      
      <template v-for="(page, pkey) in post._pages">
        <div 
          class="page page-adaptive section" 
          v-bind:class="{'page-folded': (page._catid[classActive] !== true && classActive!=false) && page._category.length > 0}" 
          v-bind:key="pkey"
          v-bind:style="{background: `rgb(${page._image.Captions[5]?page._image.Captions[5]:'255,255,255'})`}"
        >
          <template v-if="page._image">
            <video autoplay loop muted v-if="page._isvideo" :src="page._image.Files.Original"/>
            <img v-else :class="page._image.Sizes[0] > page._image.Sizes[1] ? 'landscape' : 'portrait'" :src="page._image.Files.Resized[0]">
          </template>
          <!--<div class="page-image" v-bind:style="{backgroundImage: `url(${page._image.Files.Resized[0]})` }"></div>-->
          <table class="page-foot" v-if="(page._image.Captions[$root.languageid + 1] != 'Caption' && page._image.Captions[$root.languageid + 1]) != '' || (page._image.Captions[$root.languageid + 3]  != 'Caption' && page._image.Captions[$root.languageid + 3]  != '')">
            <tr>
              <td class="page-foot-col page-foot-col-m"><span>{{post._header._year | yearformat}}</span> 
              <template v-for="(_cat, _catkey) in page._category">,
                <span v-bind:key="_catkey" v-if="_cat[$root.languageid]" v-html="_cat[$root.languageid]"></span>
              </template>
              </td>
              <td class="page-foot-col page-foot-col-m"><span v-if="page._image.Captions[$root.languageid + 1] && page._image.Captions[$root.languageid + 1] !== 'Caption'" v-html="page._image.Captions[$root.languageid + 1]"></span></td>
              <td class="page-foot-col page-foot-col-m"><span v-if="page._image.Captions[$root.languageid + 3] && page._image.Captions[$root.languageid + 3] !== 'Caption'" v-html="page._image.Captions[$root.languageid + 3]"></span></td>
            </tr>
          </table>
        </div>
        
        <div 
          class="page page-adaptive section" 
          v-if="page._breaktext"
          v-bind:key="pkey"
          v-bind:style="{background: `rgb(${page._image.Captions[5]?page._image.Captions[5]:'255,255,255'})`}"
        >
          <div class="page-text">
            <div class="page-text-container">
              <p  v-html="page._breaktext[$root.languageid]"></p>
            </div>
          </div>
        </div>
      </template>


    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loaded: false,
      post: null,
      error: null,
      hasScroll: false,
      scrollDelta: 0,
      scrollChange: 0,
      scrollMin: 100,
      scrollDir: "up",
      scrollDirBefore: "up",
      classActive: false,
      headerHeight: 0,
      permheaderHeight: 0
    }
  },
  created () {
    this.fetchData();
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      this.headerHeight = this.$refs.fixheader ? this.$refs.fixheader.clientHeight : this.headerHeight;
      this.headerHeight += 'px'

      this.permheaderHeight = this.$refs.permheader ? this.$refs.permheader.clientHeight : this.permheaderHeight;
      this.permheaderHeight += 'px'


    },
    toggleClass (key) {
      this.classActive = this.classActive == key ? false : key;
    },
    fetchData () {
      this.error = this.post = null
      this.loaded = false
      try {
        this.$store.dispatch('loadData', {mode: 'contribution', issue: this.$route.params.id}).then((data) => {
          this.post = data
          this.$store.commit('deReferencify', this.post);  
          this.$store.commit('pagify', this.post);  
          console.log(this.post)
          this.loaded = true;
          this.$nextTick(() => {
            this.resize();
          })
          let metaTags = [
            {
              name: 'keywords',
              content: this.post._header._tags.join(',')
            },
            {
              name: 'title',
              content: this.post._header._meta[this.$root.languageid]
            },
            {
              property: 'og:title',
              content: this.post._header._meta[this.$root.languageid]
            },
            {
              property: 'og:type',
              content: 'article'
            },
            {
              name: 'twitter:card',
              content: this.post._header._summary[this.$root.languageid]
            },
            {
              name: 'description',
              content: this.post._header._summary[this.$root.languageid]
            },
            {
              property: 'og:description',
              content: this.post._header._summary[this.$root.languageid]
            }
          ];

          metaTags.map(tagDef => {
            const tag = document.createElement('meta');
            Object.keys(tagDef).forEach(key => {
              tag.setAttribute(key, tagDef[key]);
            });
            tag.setAttribute('data-vue-router-controlled', '');
            return tag;
          })
          .forEach(tag => document.head.appendChild(tag));



        });  
      } catch (error) {
        this.error = error;
      }
    },
    measure() {
      if (this.$refs.body == null) return;
      if (this.$refs.body.scrollTop > this.scrollDelta) {
        this.scrollDir = "down";
      }
      if (this.$refs.body.scrollTop < this.scrollDelta) {
        this.scrollDir = "up";
      }
      if (this.scrollDirBefore != this.scrollDir) {
        this.scrollChange = this.$refs.body.scrollTop;
      }
      if (Math.abs(this.$refs.body.scrollTop - this.scrollChange) > this.scrollMin) {
        this.$root.hasScroll = this.hasScroll = this.scrollDir === "down";
      }
      this.scrollDelta = this.$refs.body.scrollTop;
      this.scrollDirBefore = this.scrollDir;
    }
  }
}
</script>

<style lang="scss">

@import "../main";

.project {

  z-index: 2;
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 0px;
  top: 0px;
  -webkit-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;

  background: #FFF;
  transition: $standard-transition;
  overflow: hidden;
  &-scroller {
    position: absolute;
    overflow-y: auto;
    bottom: 0;
    top: $standard-space;
    left: 0px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }

  &-enter-active, &-leave-active {
    transition: opacity .25s;
  }
  &-enter, &-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  bottom: 0;
  top: 0;
  z-index: 4;

        .pfilterlink {
          display: inline;
          cursor: pointer;
          &:after {
            content: ", ";
            border-bottom: 2px solid white;
          }
          &:last-child {
            &:after {
              content: "";
            } 
          }
          &-active {
            border-bottom: 1px solid;
            margin-left: 1.25em;
            &:before {
              content: "\2192 ";
              width: 1.25em;
              height: 1em;
              margin-left: -1.25em;
            }
          }
        }

  .fix {
    &-header {
      top: 0px;
      @include sm() {
        top: 0px;
      }
      width: 100%;
      left: 0px;
      z-index: 2;
      &-permanent {
        z-index: 1;
      }
      background: white;
      transition: $standard-transition;
      position: fixed;
      &-row {
        height: auto;
        min-height: $header-height;
        position: relative;
        border-bottom: 1px solid;
        padding-left: $standard-space;
        padding-right: $standard-space * 2;
        padding-bottom: $standard-space / 2;
        vertical-align: baseline;
        padding-top: $standard-space / 1.5;
        & > * {
          display: inline;
          @include mediumfont;
        }



        .close {
          position: absolute;
          right: $standard-space / 1.5;
        }
      }
      .page-head {
        width: 100%;
        padding: $standard-space $standard-space $standard-space $standard-space;
        border-spacing: 0;
        z-index: 2;
        table-layout:fixed;
        td {
            vertical-align: top;
            overflow: hidden;
            text-overflow: ellipsis;
            a {
                &:after {
                    content: ",";
                    width: 0.75em;
                    display: inline-block;
                }
                &:last-child {
                  &:after {
                      content: "";
                  }   
                }
            }
            &:nth-child(1) {
                width: 5%;
            }
            &:nth-child(2) {
                width: 23.3%;
                span {
                  cursor: pointer;
                }
            }             
            &:nth-child(3) {
                width: 5%;
            }             
            &:nth-child(4),
            &:nth-child(5) {
                width: 33.3%;
            }             
            @include sm {
                display: none;
                &:nth-child(4),
                &:nth-child(5) {
                    display: table-cell;
                }             
            }
        }
        &-active {

          &:before {
            content: "✕ " !important;
            width: 1.25em;
            display: inline-block;
          }
        }
      }
    }
  }
  &-scrolled {
    .fix-header-dynamic {
      transform: translateY(-100%);
    }
  }
}
.page {
  //-webki-scroll-snap-align: start;
  //scroll-snap-align: start;
  max-height: 2000px;
  height: auto;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  margin-bottom: $standard-space;
  @include sm() {
    padding-bottom: 0px !important;
  }
  &-folded {
    display: none;
  }
  & img {
    width: 100%;
    object-fit: contain;
    max-height: 150vh;
    &.portrait {
      max-height: 120vh;
    }
  }
  & video {
    width: 100%;
    object-fit: contain;
    max-height: calc(100vh - var(--permheader-height));
    background: #FFF;
  }
  &-text {
    -webki-scroll-snap-align: none !important;
    scroll-snap-align: none !important;
    margin: 0 $standard-space $large-space $standard-space;
    &-container {
      max-width: 66%;
      @include st {
        max-width: 75%;
      }
      @include sm {
        max-width: 550px;
      }
    }
    p {
      @include largefont();
    }
  }

  &-image {
    flex: 1;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &-foot {
    width: 100%;
    table-layout:fixed;
      max-width: $screen-sm-large;
      @include st {
        max-width: 100%;
      }
      @include sm {
        max-width: 550px;
      }
    td {
        padding: $standard-space $standard-space 0 $standard-space;
        vertical-align: top;
        &:nth-child(1) {
            width: 25%;
        }
        &:nth-child(2) {
            width: 25%;
        }             
        &:nth-child(3) {
            width: 50%;
            columns: 2;
        }             
        @include st { 
          display: block;
          width: 50% !important;
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            float: left;
          }             
          &:nth-child(3) {
            float: right;
            columns: 1;
          }
        }
        @include sm {
          width: 100% !important;
          display: block;
        }
    }
  }  
}

</style>