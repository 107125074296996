<template>
  <div class="footer-scroll-content references" v-if="post">
    <div class="references-columns">
      <template v-for="(docs, group) in post">
        <div  v-bind:key="group" style="display: inline-block;">
          <p class="references-group" v-html="group"></p>
          <p class="references-group">&nbsp;</p>
          <template v-for="doc in docs">
              <a class="references-entry" v-bind:key="doc.Contribution.Id" v-if="doc.Data.Link.Content" target="_blank" :href="doc.Data.Link.Content" v-html="`${doc.Data.Name.Parsed[0]} ${doc.Data.Name.Parsed[1]}`"></a>
              <span class="references-entry" v-bind:key="doc.Contribution.Id" v-if="!doc.Data.Link.Content" v-html="`${doc.Data.Name.Parsed[0]} ${doc.Data.Name.Parsed[1]}`"></span>
              <br v-bind:key="doc.Contribution.Id">
          </template>      
          <p class="references-entry">&nbsp;</p>
        </div>
      </template>      

      <p class="references-group">{{'Publikationen|Publications' | splitlanguage($root.languageid)}}</p>
      <p class="references-group">&nbsp;</p><br>
      <template  v-for="(docs) in publications">
        <!--
          <h1 class="references-group references-group-inset references-entry">{{year | yearformat()}}</h1>
          add references-entry-margin to following div to inset
        -->
        <template  v-for="(doc, docid) in docs">
            <a class="references-entry" v-bind:key="docid" v-if="doc.Data.Link.Content" target="_blank" :href="doc.Data.Link.Content" v-html="doc.Data.Title.Parsed"></a>
            <span class="references-entry" v-bind:key="docid" v-if="!doc.Data.Link.Content" v-html="doc.Data.Title.Parsed"></span>
            <br v-bind:key="docid">
        </template>
      </template>      
    </div>
  </div>    
</template>

<style lang="scss">
  @import "../main";
  .references {

    &-columns {
      max-width: 100%;
      columns: 3;
      @include lg {
        columns: 2;
      }
      @include sm {
        columns: 1;
      }

    }
    &-group {
      display: block;
      break-after: avoid-column;
      break-before: avoid-column;
      -webkit-break-after: avoid-column;
      -webkit-break-before: avoid-column;
      @include mediumfont;
    }
    &-entry {
      @include largefont;
      display: inline-block;
      margin: 0;
      &-margin {
        @include largefont;
        margin-bottom: $standard-space;
      }
    }
  }
</style>

<script>
import _ from 'lodash'
export default {
  data () {
    return {
      loading: false,
      post: null,
      publications: null,
      error: null
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route' () {
        this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.error = this.post = null
      this.loading = true
      let _self = this;
      try {
        this.$store.dispatch('loadData', {issue: 1, chapter: 4}).then((data) => {
          this.publications = data;
          
          let _groups = {};
          data.Documents.forEach(element => {
            let _g = element.Data.Year.Content;
            _groups[_g] = _groups[_g] || [];
            _groups[_g].push(element);
          });
          _self.publications = {};   
          _(_groups).keys().sort().each(function (key) {
            _self.publications[key] = _.orderBy(_groups[key], 'Document.Data.Title.Content', 'asc')    
          });

          this.$store.dispatch('loadData', {issue: this.$root.menufooter[this.$route.params.view].issue, chapter: this.$root.menufooter[this.$route.params.view].id}).then((data) => {
            // Groupify
            let _groups = {};
            data.Documents.forEach(element => {
              if (element.Data.Type.Reference) {
                let _g = element.Data.Type.Reference[element.Data.Type.Content].Type.Content[this.$root.languageid];
                _groups[_g] = _groups[_g] || [];
                _groups[_g].push(element);
              }
            });

            _self.post = {};   
            _(_groups).keys().each(function (key) {
              _self.post[key] = _.orderBy(_groups[key], 'Document.Data.Name.Content[1]', 'asc')    
            });

            this.loading = false;
          });  
        });
      } catch (error) {
        this.error = error;
      }
    }
  }  
}
</script>