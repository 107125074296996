<template>
  <transition name="filter">
    <div ref="filter" class="filter" v-if="$root.showfilter">
      <a class="filterlink" v-bind:class="{'filterlink-active': $store.state.activefilter === f.id}" v-on:click="filter = f.id" v-bind:key="sort" v-for="(f, sort) in filter" v-html="f.t[$root.languageid]"></a>
    </div>
  </transition>
</template>

<script>
export default {
  computed: {
    filter: {
        get: function () {
          return this.$store.state.filter
        },
        set: function (value) {
          if (this.$store.state.activefilter === value) {
            this.$store.commit('clearFilter');
          } else {
            this.$store.commit('storeFilter', value);
          }
          //this.$router.push({ name: 'home'});
        }
    }
  },
  watch: {
    '$root.showfilter': function(showfilter) {
      if(showfilter === true) {
        this.$nextTick(() => {
          if (this.$refs && this.$refs.filter) {
            this.$root.filterTopOffset = this.$refs.filter.clientHeight;
          }
        });
      }
      else {
        this.$root.filterTopOffset = 0;
      }
    }
  }
}
</script>

<style lang="scss">
 @import "../main";
.filter {
  position: absolute;
  z-index: 2;
  width: 100%;
  top: $header-height;
  height: $footer-height;
  line-height: $footer-height * 1.05;
  padding: 0 $standard-space;
  background: #FFF;
  border-bottom: 1px solid;
  transform: translateY(0%);
  &-enter-active, &-leave-active {
    transition: all .25s;
  }
  &-enter, &-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateY(-100%);
  }
  @include sm {
    display: none;
  }
  .filterlink {
    @include mediumfont;
  }
}

</style>