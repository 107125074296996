import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource'
import router from './router'

Vue.use(Vuex)
Vue.use(VueResource)


export default new Vuex.Store({
  state: {
    count: 0,
    api: 'https://kg.rokfor.ch/api',
    status: window.location.hostname.match(/localhost|192.168|10.0|beta/g) ? 'both' : 'published',
    token: 'b3d800995e5eafdbabad4a5a778fc415',
    interval: false,
    gallery: false,
    originalgallery: false,
    filter: {},
    activefilter: false
  },
  mutations: {
    storeFilter(state, id) {
      state.activefilter = id;
      state.gallery.Documents.forEach(element => {
        if (element.Data.Category && element.Data.Category.Content && element.Data.Category.Content.indexOf(state.activefilter) !== -1) {
          element.visible = true;
        }
        else {
          element.visible = false;
        }
      });
    },
    clearFilter(state) {
      state.activefilter = false;
      state.gallery.Documents.forEach(element => {
        element.visible = true;
      });
    },
    updateGallery(state, payload) {
      payload.Documents.forEach(element => {
        element.visible = true;
        try {
          element._startimage = ['gif', 'mp4'].indexOf(element.Data.ImagesStart.Content[0].Files.Original.substr(-3, 3)) !== -1
            ? element.Data.ImagesStart.Content[0].Files.Original 
            : element.Data.ImagesStart.Content[0].Files.Resized[1]
          element._isvideo = element.Data.ImagesStart.Content[0].Files.Original.substr(-3, 3) === 'mp4'

        } catch (err) {
          element._startimage = false;
          element.Data.ImagesStart = {
            Content: [element.Contribution.Name]
          }
        }

        this.commit('deReferencify', element);
        //this.commit('setTransform', {element: element, hover: 0});
      });
      state.gallery = payload;
    },
    /*setTransform(state, payload) {
      let hover = payload.hover || 0;
      let element = payload.element;
      if (element.transform == null) {
        let _x = 1;// + Math.round((Math.random() * 5));
        element.transform = [
          `margin-bottom: ${_x}em;`,
          `margin-bottom: ${_x}em;`
        ];
      }
      element.transform_active = element.transform[hover];
    },*/
    deReferencify(state, element) {
      element._reference = [];
      element._category = {};        
      element._categoryarray = [];        
      element._tags = [];       
      try {
        element.Data.Reference.Content.forEach(ref => {
          element._reference.push ({
            url: element.Data.Reference.Reference[ref].Link.Content,
            firstname: element.Data.Reference.Reference[ref].Name.Content[0],
            lastname: element.Data.Reference.Reference[ref].Name.Content[1],
            fullname: element.Data.Reference.Reference[ref].Name.Content[0] + (element.Data.Reference.Reference[ref].Name.Content[0]?' ':'') + element.Data.Reference.Reference[ref].Name.Content[1]
          })
        });
      } catch (error) {
        //return error; 
      }    
      try {    
        element.Data.Category.Content.forEach(ref => {
          Vue.set(state.filter, element.Data.Category.Reference[ref].__contribution__.Sort, {
            t: [element.Data.Category.Reference[ref].Translation.Content[0],element.Data.Category.Reference[ref].Translation.Content[1]],
            id: ref
          });
          element._category[ref] = [element.Data.Category.Reference[ref].Translation.Content[0],element.Data.Category.Reference[ref].Translation.Content[1]]
          element._categoryarray.push([element.Data.Category.Reference[ref].Translation.Content[0],element.Data.Category.Reference[ref].Translation.Content[1]])

        });
      } catch (error) {
        //return error;
      }    
      try {
        element.Data.Tag.Content.forEach(ref => {
          element._tags.push (ref)
        });
      } catch (error) {
        //return error; 
      }    
      try {
        element.Data.GrId.Content = parseInt(element.Data.GrId.Content);
      } catch (error) {
        //return error; 
      }
    },
    
    pagify(state, element) {
      if (!element.Data) return;
      element._pages = [];
      let _breaktexts = [];
      if (element.Data && element.Data['Additional Text'] && element.Data['Additional Text'].Content) {
        element.Data['Additional Text'].Content.forEach(row => {
          _breaktexts[row[0]-1] = [row[1], row[2]]
        })
      }
      element._header = {
        _id: element.Contribution.Id,
        _gretzid: element.Data.GrId && element.Data.GrId.Content ? element.Data.GrId.Content : 0,
        _title: element.Data && element.Data.Title ? element.Data.Title.Parsed : "",
        _meta: element.Data && element.Data.Title ? element.Data.Title.Content : "",
        _summary: element.Data && element.Data['Teasertext Frontpage'] ? element.Data['Teasertext Frontpage'].Content : "",
        _category: element._category,
        _tags: element._tags,
        _reference: element._reference,
        _year: element.Data &&  element.Data.Year ?  element.Data.Year.Content : 0
      };
      if (element.Data && element.Data.Images && element.Data.Images.Content) element.Data.Images.Content.forEach((images, imgkey) => {
        
        let _catref = [];
        let _catid = {};
        try {
          images.Captions[0].split(',').forEach(_cr => {
            _catref.push( element._category[_cr * 1] );
            _catid[_cr * 1] = true;
          })
        } catch (error) {
          console.log(images.Captions[0])          
        }
        element._pages.push({
          _id: element.Contribution.Id,
          _gretzid: element.Data.GrId && element.Data.GrId.Content || 0,
          _title: element.Data.Title.Parsed,
          _description: element.Data['Longtext Projectpage'].Parsed,
          _category: _catref,
          _catid: _catid,
          _tags: element._tags,
          _reference: element._reference,
          _image:  images,
          _isvideo: images.Files.Original.substr(-3, 3) === 'mp4',
          _breaktext: _breaktexts[imgkey] ? _breaktexts[imgkey] : false,
          _year: element.Data.Year.Content
        })
      });
    }
  },
  actions: {
    loadData(context, payload) {
      return new Promise((resolve, reject) => {
        payload.mode = payload.mode || 'contributions';
        Vue.http.get(`${context.state.api}/${payload.mode}${payload.issue ? `/${payload.issue}` : ''}${payload.chapter ? `/${payload.chapter}` : ''}`, {
            headers: {'Authorization': `Bearer ${context.state.token}`},
            params: payload.params || {'status': context.state.status, 'populate': 'true'}}
          ).then((response) => {
            if (response.data && !response.data.Error) {
              resolve(response.data);
              if (payload.mutator) {
                context.commit(payload.mutator, response.data);
              }
            }
            else {
              router.push("/404");
              reject(false);
            }
          }, () => {
            router.push("/404");
            reject(false);
          }
        );
      });
    }
  }
})
